import React, { useEffect } from "react";
import "../node_modules/bootstrap-4-react/dist/bootstrap-4-react";
import Routes from "./routes/routes";
// 3rd-party
import Aos from "aos";
import "aos/dist/aos.css";
// React-Router-dom
import { useLocation } from "react-router-dom";
// Dashboard-SideNavData
import {
  companySideNavData,
  personSideNavData,
  internalSideNavData,
  SalesmanSideNavData,
  companyStaffSideNavData,
} from "./components/SideNavData";
// Redux
import { useDispatch } from "react-redux";
// Slice
import {
  comapnySideNav,
  personSideNav,
  internalSideNav,
  salesmanSideNav,
  companyStaffSideNav,
} from "./slice/SideNavbar/sideNavSlice";

function App() {
  // UseDispatch-hook
  const dispatch = useDispatch();
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 2500,
      easing: "ease",
      delay: 100,
      once: "true",
    });
  }, [Aos]);
  // location
  const location = useLocation();
  // SideNav-Code-start
  let newCompanySideNavData = [];
  let newPersonSideNavData = [];
  let newInternalSideNavData = [];
  let newSalesmanSideNavData = [];
  let newCompanyStaffSideNavData = [];
  // New-SideNav-Generator-start
  const sidenavGenerator = (data) => {
    data.map((val) => {
      if (data === companySideNavData) {
        newCompanySideNavData = [
          ...newCompanySideNavData,
          {
            title: val.title,
            route:
              val.route === "/company-loyalty-points"
                ? location.pathname
                : val.route,
          },
        ];
      } else if (data === personSideNavData) {
        newPersonSideNavData = [
          ...newPersonSideNavData,
          {
            title: val.title,
            route:
              val.route === "/person-loyalty-points"
                ? location.pathname
                : val.route,
          },
        ];
      } else if (data === internalSideNavData) {
        newInternalSideNavData = [
          ...newInternalSideNavData,
          { title: val.title, route: val.route },
        ];
      } else if (data === SalesmanSideNavData) {
        newSalesmanSideNavData = [
          ...newSalesmanSideNavData,
          { title: val.title, route: val.route },
        ];
      } else if (data === companyStaffSideNavData) {
        newCompanyStaffSideNavData = [
          ...newCompanyStaffSideNavData,
          {
            title: val.title,
            route:
              val.route === "/company-loyalty-points"
                ? location.pathname
                : val.route,
          },
        ];
      }
    });
  };
  // New-SideNav-Generator-end
  useEffect(() => {
    sidenavGenerator(companyStaffSideNavData);
    sidenavGenerator(companySideNavData);
    sidenavGenerator(personSideNavData);
    sidenavGenerator(internalSideNavData);
    sidenavGenerator(SalesmanSideNavData);
    dispatch(comapnySideNav(newCompanySideNavData));
    dispatch(personSideNav(newPersonSideNavData));
    dispatch(internalSideNav(newInternalSideNavData));
    dispatch(salesmanSideNav(newSalesmanSideNavData));
    dispatch(companyStaffSideNav(newCompanyStaffSideNavData));
  }, []);
  // SideNav-Code-end

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
