import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyNav: [],
  personNav: [],
  internalNav: [],
  salesmanNav: [],
  companyStaffNav: [],
};

export const sideNavSlice = createSlice({
  name: "sidenavSlice",
  initialState,
  reducers: {
    comapnySideNav: (state, action) => {
      state.companyNav = action.payload;
    },
    personSideNav: (state, action) => {
      state.personNav = action.payload;
    },
    internalSideNav: (state, action) => {
      state.internalNav = action.payload;
    },
    salesmanSideNav: (state, action) => {
      state.salesmanNav = action.payload;
    },
    companyStaffSideNav: (state, action) => {
      state.companyStaffNav = action.payload;
    },
  },
});

export const {
  comapnySideNav,
  personSideNav,
  internalSideNav,
  salesmanSideNav,
  companyStaffSideNav,
} = sideNavSlice.actions;

export default sideNavSlice.reducer;
