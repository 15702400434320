// User-Data
export const getUserStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("USER-INFO"));
  } catch {
    return null;
  }
};
// Company-Data
export const getCompanyStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("COMPANY-INFO"));
  } catch {
    return null;
  }
};
// Vehicle-Booking-Data
export const VehicleBookingStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("VEHICLE-BOOKING-INFO"));
  } catch {
    return null;
  }
};
// Vehicle_details-Data
export const VehicleDetailsStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("VEHICLE-DETAILS"));
  } catch {
    return null;
  }
};
// Enquiry-Data
export const enquiryStorage = () => {
  try {
    return localStorage.getItem("ENQUIRY-VEHICLE");
  } catch {
    return null;
  }
};
// Vehicle-Select-Data
export const vehicleSelectStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("MODEL-BOOK"));
  } catch {
    return null;
  }
};
// Stripe-Response
export const stripeResponseStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("STRIPE-RESPONSE"));
  } catch {
    return null;
  }
};
// Outstanding-Invoices-Selection
export const OInvoiceSelectStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("OUTSTANDING-INVOICE-SELECTION"));
  } catch {
    return null;
  }
};
// SELECTED-INVOICE
export const SelectedOInvoiceStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("SELECTED-INVOICE"));
  } catch {
    return null;
  }
};
// SERVICE-BOOKING
export const ServiceBookingSelector = () => {
  try {
    return JSON.parse(localStorage.getItem("SERVICE-BOOKING-SELECTION"));
  } catch {
    return null;
  }
};
// REPAYMENT-SELECTION
export const RepaymentSelection = () => {
  try {
    return localStorage.getItem("REPAYMENT-SELECTION");
  } catch {
    return null;
  }
};
//SELECTED-BOC-REPAY
export const SelectedBocRepay = () => {
  try {
    return JSON.parse(localStorage.getItem("SELECTED-BOC-REPAY"));
  } catch {
    return null;
  }
};
// SELECTED-ACCIDENT-REPAY
export const SelectedAccidentRepay = () => {
  try {
    return JSON.parse(localStorage.getItem("SELECTED-ACCIDENT-REPAY"));
  } catch {
    return null;
  }
};
