import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../slice/Login/loginSlice";
import loginUserReducer from "../slice/Login/loginUserSlice";
import sideNavReducer from "../slice/SideNavbar/sideNavSlice";
import otpSlice from "../slice/OTP/otpSlice";
import signatureReducer from "../slice/Signature/signatureSlice";
import fileSlice from "../slice/Files/files64Slice";
import editRentalQuotationSlice from "../slice/RentalQuotation/quoteSlice";

export const store = configureStore({
  reducer: {
    loginStatus: loginReducer,
    loginUserInfo: loginUserReducer,
    sideNavData: sideNavReducer,
    otpNumber: otpSlice,
    signatureBase64: signatureReducer,
    filesBase64: fileSlice,
    editRentalQuotation: editRentalQuotationSlice,
  },
});
