import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";

const companySideNavData = [
  {
    title: "Home",
    icon: <FaIcons.FaHome />,
    route: "/",
  },
  {
    title: "Account Details",
    icon: <FaIcons.FaUserCog />,
    route: "/company-account-details",
  },
  {
    title: "Staff Members",
    icon: <FaIcons.FaUsers />,
    route: "/company-staff-member",
  },
  {
    title: "Rental Details",
    icon: <FaIcons.FaFileInvoiceDollar />,
    route: "/company-rental-details",
  },
  {
    title: "Continue Booking",
    icon: <FaIcons.FaTruck />,
    route: "/company-continue-booking",
  },
  {
    title: "Loyalty Points",
    icon: <MdIcons.MdLoyalty />,
    route: "/company-loyalty-points",
  },
];

const companyStaffSideNavData = [
  {
    title: "Home",
    icon: <FaIcons.FaHome />,
    route: "/",
  },
  {
    title: "Account Details",
    icon: <FaIcons.FaUserCog />,
    route: "/company-account-details",
  },
  {
    title: "Rental Details",
    icon: <FaIcons.FaFileInvoiceDollar />,
    route: "/company-rental-details",
  },
  {
    title: "Continue Booking",
    icon: <FaIcons.FaTruck />,
    route: "/company-continue-booking",
  },
  {
    title: "Loyalty Points",
    icon: <MdIcons.MdLoyalty />,
    route: "/company-loyalty-points",
  },
];

const personSideNavData = [
  {
    title: "Home",
    icon: <FaIcons.FaHome />,
    route: "/",
  },
  {
    title: "Account Details",
    icon: <FaIcons.FaUserCog />,
    route: "/person-account-details",
  },
  {
    title: "Rental Details",
    icon: <FaIcons.FaFileInvoiceDollar />,
    route: "/person-rental-details",
  },

  {
    title: "Continue Booking",
    icon: <FaIcons.FaTruck />,
    route: "/person-continue-booking",
  },
  {
    title: "Loyalty Points",
    icon: <MdIcons.MdLoyalty />,
    route: "/person-loyalty-points",
  },
];

const internalSideNavData = [
  {
    title: "Home",
    icon: <FaIcons.FaHome />,
    route: "/",
  },
  {
    title: "Sales Commission",
    icon: <FaIcons.FaUserCog />,
    route: "/internal-sales-commission",
  },
  {
    title: "Rental Quotation",
    icon: <MdIcons.MdRequestQuote />,
    route: "/internal-rental-quotation",
  },
  {
    title: "Rental Contract",
    icon: <FaIcons.FaFileContract />,
    route: "/internal-rental-contract",
  },
  {
    title: "Lead Master",
    icon: <GiIcons.GiMasterOfArms />,
    route: "/internal-lead-master",
  },
];

const SalesmanSideNavData = [
  {
    title: "Home",
    icon: <FaIcons.FaHome />,
    route: "/",
  },
  {
    title: "Dashboard",
    icon: <MdIcons.MdDashboard />,
    route: "/salesman-dashboard",
  },
  {
    title: "Sales Commission",
    icon: <FaIcons.FaUserCog />,
    route: "/salesman-sales-commission",
  },
  {
    title: "Rental Quotation",
    icon: <MdIcons.MdRequestQuote />,
    route: "/salesman-rental-quotation",
  },
  {
    title: "Rental Contract",
    icon: <FaIcons.FaFileContract />,
    route: "/salesman-rental-contract",
  },
  {
    title: "Lead Master",
    icon: <GiIcons.GiMasterOfArms />,
    route: "/salesman-lead-master",
  },
];

export {
  companySideNavData,
  companyStaffSideNavData,
  personSideNavData,
  internalSideNavData,
  SalesmanSideNavData,
};
