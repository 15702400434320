import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filesBase64: [],
};

export const filesSlice = createSlice({
  name: "FileBase64",
  initialState,
  reducers: {
    filesBase64: (state, action) => {
      state.filesBase64 = action.payload;
    },
  },
});

export const { filesBase64 } = filesSlice.actions;

export default filesSlice.reducer;
