import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rentalQuoteEdited: {},
};

export const editRentalQuotationSlice = createSlice({
  name: "Rental Quotation Edit",
  initialState,
  reducers: {
    editQuotation: (state, action) => {
      state.rentalQuoteEdited = action.payload;
    },
  },
});

export const { editQuotation } = editRentalQuotationSlice.actions;

export default editRentalQuotationSlice.reducer;
