import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signatureBase64: "",
};

export const signatureSlice = createSlice({
  name: "Signature",
  initialState,
  reducers: {
    signatureBase64: (state, action) => {
      state.signatureBase64 = action.payload;
    },
  },
});

export const { signatureBase64 } = signatureSlice.actions;

export default signatureSlice.reducer;
