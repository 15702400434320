import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otp: "",
  otpSubmit: false,
};

export const otpSlice = createSlice({
  name: "OTP",
  initialState,
  reducers: {
    otpNum: (state, action) => {
      state.otp = action.payload;
    },
    otpSubmit: (state, action) => {
      state.otpSubmit = action.payload;
    },
  },
});

export const { otpNum, otpSubmit } = otpSlice.actions;

export default otpSlice.reducer;
