import React, { Suspense, lazy } from "react";
// 3rd-party
import { Switch, Route, Redirect } from "react-router-dom";
import { PuffLoader } from "react-spinners";
// LocalStorages
import {
  getUserStorage,
  VehicleBookingStorage,
  VehicleDetailsStorage,
  enquiryStorage,
  vehicleSelectStorage,
  stripeResponseStorage,
  OInvoiceSelectStorage,
  SelectedOInvoiceStorage,
  ServiceBookingSelector,
  RepaymentSelection,
  SelectedBocRepay,
  SelectedAccidentRepay,
} from "../localStorage";
// Component-for-scroll-to-top
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
// pages
const HomePage = lazy(() => import("../pages/HomePage"));
const Login = lazy(() => import("../pages/Login"));
const SignUp = lazy(() => import("../pages/SignUp"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const Myinfo = lazy(() => import( "../pages/Myinfo"));
// Company-Dashboard-pages
const CompanyAccountDetails = lazy(() =>
  import("../pages/CompanyDashboard/AccountDetails")
);
const CompanyStaffMember = lazy(() =>
  import("../pages/CompanyDashboard/StaffMember")
);
const CompanyRentalDetails = lazy(() =>
  import("../pages/CompanyDashboard/RentalDetails")
);
const CompanyContinueBooking = lazy(() =>
  import("../pages/CompanyDashboard/ContinueBooking")
);
const CompanyLoyaltyPoints = lazy(() =>
  import("../pages/CompanyDashboard/LoyaltyPoints")
);
const CompanyInvoiceCheckout = lazy(() =>
  import("../pages/CompanyDashboard/CheckOut/InvoiceCheckout")
);
const CompanyBOCCheckout = lazy(() =>
  import("../pages/CompanyDashboard/CheckOut/BOCCheckout")
);
const CompanyAccidentCheckout = lazy(() =>
  import("../pages/CompanyDashboard/CheckOut/AccidentCheckout")
);
const CompanyInvoiceSuccess = lazy(() =>
  import("../pages/CompanyDashboard/PaymentSuccess/InvoicePaymentSuccessfull")
);
const CompanyBocRepaySuccess = lazy(() =>
  import("../pages/CompanyDashboard/PaymentSuccess/BocRepaySuccess")
);
const CompanyAccidentRepaySuccess = lazy(() =>
  import("../pages/CompanyDashboard/PaymentSuccess/AccidentRepaySuccess")
);
const CompanyOutstandingInvoices = lazy(() =>
  import("../pages/CompanyDashboard/OutstandingInvoices")
);
const CompanyBOCRepayment = lazy(() =>
  import("../pages/CompanyDashboard/BOCRepayment")
);
const CompanyAccidentRepayment = lazy(() =>
  import("../pages/CompanyDashboard/AccidentRepayment")
);
const CompanyServiceBooking = lazy(() =>
  import("../pages/CompanyDashboard/ServiceBooking")
);
// Person-Dashboard-Pages
const PersonAccountDetails = lazy(() =>
  import("../pages/PersonDashboard/AccountDetails")
);
const PersonRentalDetails = lazy(() =>
  import("../pages/PersonDashboard/RentalDetails")
);
const PersonInvoiceCheckout = lazy(() =>
  import("../pages/PersonDashboard/CheckOut/InvoiceCheckout")
);
const PersonInvoiceSuccess = lazy(() =>
  import("../pages/PersonDashboard/PaymentSuccess/InvoicePaymentSuccessfull")
);
const PersonLoyaltyPoints = lazy(() =>
  import("../pages/PersonDashboard/LoyaltyPoints")
);
const PersonContinueBooking = lazy(() =>
  import("../pages/PersonDashboard/ContinueBooking")
);
const PersonOutstandingInvoices = lazy(() =>
  import("../pages/PersonDashboard/OutstandingInvoices")
);
const PersonBOCRepayment = lazy(() =>
  import("../pages/PersonDashboard/BOCRepayment")
);
const PersonBOCCheckout = lazy(() =>
  import("../pages/PersonDashboard/CheckOut/BOCCheckout")
);
const PersonBocRepaySuccess = lazy(() =>
  import("../pages/PersonDashboard/PaymentSuccess/BocRepaySuccess")
);
const PersonAccidentRepayment = lazy(() =>
  import("../pages/PersonDashboard/AccidentRepayment")
);
const PersonAccidentCheckout = lazy(() =>
  import("../pages/PersonDashboard/CheckOut/AccidentCheckout")
);
const PersonAccidentRepaySuccess = lazy(() =>
  import("../pages/PersonDashboard/PaymentSuccess/AccidentRepaySuccess")
);
const PersonServiceBooking = lazy(() =>
  import("../pages/PersonDashboard/ServiceBooking")
);
// INTERNAL-DASHBOARD-START
const InternalSalesCommision = lazy(() =>
  import("../pages/InternalDashboard/SalesCommision")
);
const InternalLeadMaster = lazy(() =>
  import("../pages/InternalDashboard/LeadMaster")
);
const InternalRentalQuotation = lazy(() =>
  import("../pages/InternalDashboard/RentalQuotation")
);
const InternalRentalContract = lazy(() =>
  import("../pages/InternalDashboard/RentalContract")
);
// SALESMAN_MANAGEMENT-DASHBOARD-START
const SalesmanSalesCommision = lazy(() =>
  import("../pages/SalesmanManagementDashboard/SalesCommision")
);
const SalesmanRentalQuotation = lazy(() =>
  import("../pages/SalesmanManagementDashboard/RentalQuotation")
);
const SalesmanRentalContract = lazy(() =>
  import("../pages/SalesmanManagementDashboard/RentalContract")
);
const SalesmanLeadMaster = lazy(() =>
  import("../pages/SalesmanManagementDashboard/LeadMaster")
);
const SalesmanDashboard = lazy(() =>
  import("../pages/SalesmanManagementDashboard/Dashboard")
);
const ContactUs = lazy(() => import("../pages/ContactUs"));
const VehicleBooking = lazy(() => import("../pages/VehicleBooking"));
const VehicleDetail = lazy(() => import("../pages/VehicleDetail"));
const EnquiryForm = lazy(() => import("../pages/EnquiryForm"));
const CheckOutBooking = lazy(() => import("../pages/CheckOut"));
const LeaseAgreement = lazy(() => import("../pages/LeaseAgreement"));
const VehicleBookingSuccess = lazy(() =>
  import("../pages/vehicleBookingSuccess")
);
const VehicleBookingFailed = lazy(() =>
  import("../pages/vehicleBookingFailed")
);
const VehicleBookingRepayment = lazy(() =>
  import("../pages/VehicleBookingRepayment")
);

const spinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function routes() {
  return (
    <>
      <Suspense
        fallback={<PuffLoader color={"#3C88BB"} size={60} css={spinnerStyle} />}
      >
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/login"
            component={(props) =>
              !getUserStorage() ? <Login {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/sign-up"
            component={(props) =>
              !getUserStorage() ? <SignUp {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/reset-password"
            component={(props) =>
              !getUserStorage() ? (
                <ResetPassword {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          {/* Company-Dashboard-Routes-Start */}
          <Route
            exact
            path="/company-account-details"
            component={(props) =>
              getUserStorage() ? (
                <CompanyAccountDetails {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-staff-member"
            component={(props) =>
              getUserStorage() ? (
                <CompanyStaffMember {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-rental-details"
            component={(props) =>
              getUserStorage() ? (
                <CompanyRentalDetails {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-outstanding-invoice/checkout"
            component={(props) =>
              getUserStorage() && SelectedOInvoiceStorage() ? (
                <CompanyInvoiceCheckout {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-outstanding-invoice/payment-successfull"
            component={(props) =>
              getUserStorage() &&
              SelectedOInvoiceStorage() &&
              stripeResponseStorage() ? (
                <CompanyInvoiceSuccess {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-loyalty-points"
            component={(props) =>
              getUserStorage() ? (
                <CompanyLoyaltyPoints {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-continue-booking"
            component={(props) =>
              getUserStorage() ? (
                <CompanyContinueBooking {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-outstanding-invoice"
            component={(props) =>
              getUserStorage() && OInvoiceSelectStorage() ? (
                <CompanyOutstandingInvoices {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/company-boc-repayment"
            component={(props) =>
              getUserStorage() && RepaymentSelection() ? (
                <CompanyBOCRepayment {...props} />
              ) : (
                <Redirect to="/company-rental-details" />
              )
            }
          />
          <Route
            exact
            path="/company-boc-repayment/checkout"
            component={(props) =>
              getUserStorage() && SelectedBocRepay() ? (
                <CompanyBOCCheckout {...props} />
              ) : (
                <Redirect to="/company-boc-repayment" />
              )
            }
          />
          <Route
            exact
            path="/company-boc-repayment/payment-successfull"
            component={(props) =>
              getUserStorage() && stripeResponseStorage() ? (
                <CompanyBocRepaySuccess {...props} />
              ) : (
                <Redirect to="/company-boc-repayment/checkout" />
              )
            }
          />
          <Route
            exact
            path="/company-accident-repayment"
            component={(props) =>
              getUserStorage() && RepaymentSelection() ? (
                <CompanyAccidentRepayment {...props} />
              ) : (
                <Redirect to="/company-rental-details" />
              )
            }
          />
          <Route
            exact
            path="/company-accident-repayment/checkout"
            component={(props) =>
              getUserStorage() && SelectedAccidentRepay() ? (
                <CompanyAccidentCheckout {...props} />
              ) : (
                <Redirect to="/company-accident-repayment" />
              )
            }
          />
          <Route
            exact
            path="/company-accident-repayment/payment-successfull"
            component={(props) =>
              getUserStorage() && stripeResponseStorage() ? (
                <CompanyAccidentRepaySuccess {...props} />
              ) : (
                <Redirect to="/company-accident-repayment/checkout" />
              )
            }
          />
          <Route
            exact
            path="/company-service-booking"
            component={(props) =>
              getUserStorage() && ServiceBookingSelector() ? (
                <CompanyServiceBooking {...props} />
              ) : (
                <Redirect to="/company-rental-details" />
              )
            }
          />
          {/* Company-Dashboard-Routes-End */}
          {/* Person-Dashboard-Routes-Start */}
          <Route
            exact
            path="/person-account-details"
            component={(props) =>
              getUserStorage() ? (
                <PersonAccountDetails {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-rental-details"
            component={(props) =>
              getUserStorage() ? (
                <PersonRentalDetails {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-outstanding-invoice/checkout"
            component={(props) =>
              getUserStorage() && SelectedOInvoiceStorage() ? (
                <PersonInvoiceCheckout {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-outstanding-invoice/payment-successfull"
            component={(props) =>
              getUserStorage() &&
              SelectedOInvoiceStorage() &&
              stripeResponseStorage() ? (
                <PersonInvoiceSuccess {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-loyalty-points"
            component={(props) =>
              getUserStorage() ? (
                <PersonLoyaltyPoints {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-continue-booking"
            component={(props) =>
              getUserStorage() ? (
                <PersonContinueBooking {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-outstanding-invoice"
            component={(props) =>
              getUserStorage() && OInvoiceSelectStorage() ? (
                <PersonOutstandingInvoices {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/person-boc-repayment"
            component={(props) =>
              getUserStorage() && RepaymentSelection() ? (
                <PersonBOCRepayment {...props} />
              ) : (
                <Redirect to="/person-rental-details" />
              )
            }
          />
          <Route
            exact
            path="/person-boc-repayment/checkout"
            component={(props) =>
              getUserStorage() && SelectedBocRepay() ? (
                <PersonBOCCheckout {...props} />
              ) : (
                <Redirect to="/person-boc-repayment" />
              )
            }
          />
          <Route
            exact
            path="/person-boc-repayment/payment-successfull"
            component={(props) =>
              getUserStorage() && stripeResponseStorage() ? (
                <PersonBocRepaySuccess {...props} />
              ) : (
                <Redirect to="/person-boc-repayment/checkout" />
              )
            }
          />
          <Route
            exact
            path="/person-accident-repayment"
            component={(props) =>
              getUserStorage() && RepaymentSelection() ? (
                <PersonAccidentRepayment {...props} />
              ) : (
                <Redirect to="/person-rental-details" />
              )
            }
          />
          <Route
            exact
            path="/person-accident-repayment/checkout"
            component={(props) =>
              getUserStorage() && SelectedAccidentRepay() ? (
                <PersonAccidentCheckout {...props} />
              ) : (
                <Redirect to="/person-accident-repayment" />
              )
            }
          />
          <Route
            exact
            path="/person-accident-repayment/payment-successfull"
            component={(props) =>
              getUserStorage() && stripeResponseStorage() ? (
                <PersonAccidentRepaySuccess {...props} />
              ) : (
                <Redirect to="/person-accident-repayment/checkout" />
              )
            }
          />
          <Route
            exact
            path="/person-service-booking"
            component={(props) =>
              getUserStorage() && ServiceBookingSelector() ? (
                <PersonServiceBooking {...props} />
              ) : (
                <Redirect to="/person-rental-details" />
              )
            }
          />
          {/* Person-Dashboard-Routes-End */}
          {/* Internal-Dashboard-start */}
          <Route
            exact
            path="/internal-sales-commission"
            component={(props) =>
              getUserStorage() ? (
                <InternalSalesCommision {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/internal-lead-master"
            component={(props) =>
              getUserStorage() ? (
                <InternalLeadMaster {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/internal-rental-quotation"
            component={(props) =>
              getUserStorage() ? (
                <InternalRentalQuotation {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/internal-rental-contract"
            component={(props) =>
              getUserStorage() ? (
                <InternalRentalContract {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          {/* Internal-Dashboard-end */}
          {/* Salesman-Management-Dashboard-start */}
          <Route
            exact
            path="/salesman-sales-commission"
            component={SalesmanSalesCommision}
          />
          <Route
            exact
            path="/salesman-rental-quotation"
            component={SalesmanRentalQuotation}
          />
          <Route
            exact
            path="/salesman-rental-contract"
            component={SalesmanRentalContract}
          />
          <Route
            exact
            path="/salesman-lead-master"
            component={SalesmanLeadMaster}
          />
          <Route
            exact
            path="/salesman-dashboard"
            component={SalesmanDashboard}
          />
          {/* Salesman-Management-Dashboard-end */}
          <Route
            exact
            path="/vehicle-booking"
            component={(props) =>
              getUserStorage() && vehicleSelectStorage() ? (
                <VehicleBooking {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { goFor: "/vehicle-booking" },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/checkout-booking"
            component={(props) =>
              getUserStorage() && VehicleBookingStorage() ? (
                <CheckOutBooking {...props} />
              ) : (
                <Redirect to="/vehicle-booking" />
              )
            }
          />
          <Route exact path="/lease-agreement-T&C" component={LeaseAgreement} />
          <Route
            exact
            path="/vehicle-details"
            component={(props) =>
              VehicleDetailsStorage() ? (
                <VehicleDetail {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/enquiry-form"
            component={(props) =>
              getUserStorage() && enquiryStorage() ? (
                <ContactUs {...props} />
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { goFor: "/enquiry-form" } }}
                />
              )
            }
          />
          <Route
            exact
            path="/payment-processing"
            component={(props) =>
              getUserStorage() &&
              stripeResponseStorage() &&
              VehicleBookingStorage() ? (
                <VehicleBookingSuccess {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/vehicle-booking-repayment"
            component={VehicleBookingRepayment}
          />
          <Route
            exact
            path="/payment-failed"
            component={(props) =>
              getUserStorage() &&
              stripeResponseStorage() &&
              VehicleBookingStorage() ? (
                <VehicleBookingFailed {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/callback"
            component={(props) => <Myinfo/>}
          />
        </Switch>
      </Suspense>
    </>
  );
}
